@tailwind base;
@tailwind components;
@tailwind utilities;

.service_card:hover img {
  filter: brightness(0.5);
}

/* Navbar.css */

.navbar-container {
  width: 100%;
  transition: all 0.5s ease-in-out;
  z-index: 1000;
}

.fixed-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.animate-navbar {
  animation: fadeIn 1s forwards, slideInDown 1s forwards;
}

/* Fade In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Slide In Down Animation */
@keyframes slideInDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}


.mobile_slider {
  position: fixed;
  z-index: 9999999;
  background: white;
  width: 100%;
  border-bottom: 2px solid #225da5;
  padding: 6px 7px;
}

@media (max-width: 768px) {

  th:nth-child(1),
  th:nth-child(3),
  th:nth-child(4),
  th:nth-child(5),
  td:nth-child(1),
  td:nth-child(3),
  td:nth-child(4),
  td:nth-child(5) {
    display: none;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 100%;
    display: block;
    text-align: left;
  }
}


.text {
  text-shadow: 1px 2px 2px #225da39e;
}

.navbar_section .nav-link {
  position: relative;
  color: #225DA5;
  transition: color 0.3s ease;
}

.navbar_section .nav-link::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #a2373a;
  /* Change to your customRed color */
  transition: width 0.3s ease;
}

.navbar_section .nav-link:hover {
  color: #a2373a;
  /* Change to your customRed color */
}

.navbar_section .nav-link:hover::after {
  width: 100%;
  left: 0;
}

.navbar_section .active-link {
  color: #a2373a;
}

.navbar_section .active-link::after {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #a2373a;
  /* Change to your customRed color */
}


.slides_logo_clint {
  width: 200px !important;
}

.home_about_img {
  border: 5px solid #a2373a;
  border-radius: 6px;
  box-shadow: 5px 8px 16px #225da5;
}

.welcome_text {
  position: relative;
  display: inline-block;
}

.welcome_text::after {
  content: "";
  position: absolute;
  background-color: #765e5f;
  width: 346px;
  height: 4px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1px;
  border-radius: 111px 10px 81px 10px;
  border: 1px solid;
}


@media only screen and (max-width: 480px) {
  .welcome_text::after {
    width: 178px;
  }
}


.modal {
  position: fixed;
  top: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  outline: none;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); /* Set the background to a semi-transparent black */
  z-index: 1000;
}

.close-modal-button {
  background-color: #151515a3;
  color: white;
  border: none;
  padding: 10px 16px;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 50px;
}

.next-image-button {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #008CBA;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.placeholder-custom::placeholder {
  color:black; 
  opacity: 1; 
}